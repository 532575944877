const ind={
	jiantizhongwen:'bahasa Indonesia',
    xianlu:'garis',
    dianwei:'Poin saat ini',
    wodezichan:'aset saya',
    touzikuaixun:'Berita Investasi',
    jiaoyiyonghu:'pengguna perdagangan',
    jiaoyipinglei:'Kategori Transaksi',
    danlunhuoli:'Untung dalam satu putaran',
    jiaoyiriqi:'tanggal transaksi',
    dengluhouchakan:'Lihat setelah masuk',
    shouye:'halaman Depan',
    jiaoyidating:'Bursa saham',
    yucun:'Pra-simpan',
    zoushi:'kecenderungan',
    wode:'milikku',
    zuiditouzi:'Investasi minimal',
    zuidijinru:'entri minimum',
    jichulicai:'Basis',
    zishenlicai:'Senior',
    dashilicai:'Guru Besar',
    zhuanjialicai:'pakar',
	基础理财:'Basis',
    资深理财:'Senior',
    大师理财:'Guru Besar',
    专家理财:'pakar',
	基础:'Basis',
    资深:'Senior',
    大师:'Guru Besar',
    专家:'pakar',
    huiyuanquanyi:'manfaat anggota',
    zuoriyingli:'Keuntungan kemarin',
    zongzichan:'Total aset',
    chucunjin:'menyetorkan',
    jinriyingli:'Keuntungan hari ini',
    zhuanchu:'transfer keluar',
    cunru:'Menyetorkan',
    zhanghuxinxni:'Informasi Akun',
    lishizhangdan:'RUU bersejarah',
    zijinminxi:'Detail dana',
    renwuzhongxin:'pusat misi',
    xiaoxigonggao:'Pengumuman berita',
    tuijianfenxiang:'Merekomendasikan berbagi',
    huodongzhongxin:'pusat kegiatan',
    jibenziliao:'informasi dasar',
    dengluzhanghao:'Masuk akun',
    nicheng:'nama panggilan',
    shezhi:'mempersiapkan',
    genggaixianlu:'Ubah rute',
    guanyuwomen:'tentang kami',
    xitongshezhi:'Pengaturan sistem',
    yuyanshezhi:'pengaturan bahasa',
    tuichudenglu:'keluar',
    bangdingyinhangka:'Ikat kartu bank',
    bangdingxuniqianbao:'Ikat dompet virtual',
    anquanma:'Kode keamanan',
    xiugailenglumima:'Ubah kata sandi masuk',
    yinhangkaguanli:'Manajemen kartu bank',
    tianjiayinhangka:'Tambahkan kartu bank',
    xuniqianbaoguanli:'Manajemen dompet virtual',
    tianjiaxuniqianbao:'Tambahkan dompet virtual',
    chiakren:'pemegang kartu',
    yinhangkakahao:'Nomor kartu bank',
    yinhangmingcheng:'nama Bank',
    yhkTps:'Silakan ikat kartu bank pemegang kartu',
    nixiqianbaodizhi:'Alamat dompet virtual',
    xuniqianbaoleixing:'Jenis dompet virtual',
    xnqbTps:' Pengingat hangat: Silakan isi jenis alamat USDT, seperti: trc20, erc20, omni ',
    plchikaren:'Silakan masukkan pemegang kartu',
    plkahao:'Silakan masukkan nomor kartu pemegang kartu',
    plyinhangmingcheng:'Silakan masukkan nama bank',
    plqbdz:'Silakan masukkan alamat dompet virtual',
    plqblx:'Silakan masukkan jenis dompet virtual',
    shezhianquanma:'Tetapkan kode keamanan',
    yuananquanma:'Kode keamanan asli',
    xinanquanma:'Kode keamanan baru',
    planquanma:'Silakan masukkan kode keamanan asli',
    plxinanquanma:'Silakan masukkan kode keamanan baru',
    queren:'mengonfirmasi',
	quxiao:'Membatalkan',
    anquanmaTps:' Harap tetapkan kode keamanan, yang tidak boleh sama dengan kata sandi kartu bank.',
    anquanmaTpsTow:' Pelanggan yang terhormat, kode keamanan ini adalah kata sandi Anda saat mentransfer keluar.Untuk alasan keamanan, harap usahakan tidak sama dengan kata sandi login Anda.',
    xiugaidenglu:'Ubah kata sandi masuk',
    jiumima:'password lama',
    xinmima:'kata sandi baru',
    querenmima:'konfirmasi sandi',
    pljiumima:'Silakan masukkan kata sandi lama',
    plxinmima:'Silakan masukkan kata sandi baru',
    plquerenmima:'Silakan masukkan kata sandi lagi',
    wancheng:'Menyelesaikan',
    kaishitouzi:'Mulailah berinvestasi',
    kaiTps:'Jika saluran saat ini tidak tersedia, coba beralih ke saluran lain',
    zuiyou:'optimal',
    dangaqianxianlu:'Garis saat ini',
    dangqianbanben:'versi sekarang',
    banquansuoyou:'seluruh hak cipta',
    yinglizhendong:'Kejutan pendapatan',
    gensuixitong:'Ikuti sistemnya',
    zhuanchujine:'Jumlah transfer',
    plzhuanchu:'Silakan masukkan jumlah transfer',
    zhuanchuTps:'Pengingat hangat: Jika Anda memiliki pertanyaan, silakan hubungi layanan pelanggan platform.',
    xuanzeyh:'Silakan pilih kartu bank penarikan',
    xuanzehb:'Silakan pilih dompet virtual untuk penarikan',
    tijiaoshenqing:'mengajukan permohonan',
    shuruanquanma:'Silakan masukkan kode keamanan',
    xianshangcunru:'Setor secara daring',
    saomacunru:'USDT',
    wangyincunru:"deposit bank",
    shijian:'waktu',
    upshijian:'Waktu mulai',
    downshijian:'Akhir waktu',
    wuTps:'Belum ada data yang relevan~',
    jiazaicx:'Muat ulang',
    di:'TIDAK.',
    lun:'roda',
    weiyingli:'Tidak menguntungkan',
    yishouli:'Diterima',
    zhuangtai:'negara',
    chongzhi:'mengatur ulang',
    riqi:'tanggal',
    CapActive:{
        chongzhi:'isi ulang',
        tixian:'menarik',
        goumai:'Membeli',
        yingli:'laba',
        zengsong:'memberi secara gratis',
        weitongguo:'Tidak lulus',
        yitongguo:'lulus',
        yijujue:'ditolak',
    },
    zhanneixiaoxi:"Berita stasiun",
    pingtaigonggao:'Pengumuman platform',
    fuzhilanjie:"Salin tautan",
    denglu:'Gabung',
    zhuce:'daftar',
    jizhumima:'Ingat kata Sandi',
    wangjimima:'lupa kata sandinya',
    youkedenglu:'Login pengunjung',
    zhucezhanghao:"daftar akun",
    plusername:'silakan masukkan nama pengguna',
    plpassword:'Silakan masukkan kata sandi',
    wanchengzhuce:'Pendaftaran lengkap',
    yiyouzhanghao:'Sudah memiliki akun',
    qingdenglu:'silakan masuk',
    remenhuati:'topik hangat',
    news1:'Asli',
    news2:'menyarankan',
    news3:'kekayaan',
    renliulan:'Orang-orang menelusuri',
    hezhi:'nilai jumlah',
    lunshu:'Jumlah putaran',
    做多:'nilai berlebih',
    做空:'Nilai catatan',
    多单:'Yiqi',
    多双:'meluap',
    空单:'Perhatikan keanehannya',
    空双:'Catatan genap',
    平单:'nilai ganjil',
    平双:'nilai genap',
	极阴:'Sangat Yin',
	极阳:'Sangat Yang',
    qi:'Mengharapkan',
    juli:'jarak',
    lunjiezhi:'Akhir putaran',
    yifengpan:'Tertutup',
    yiguanbi:'tertutup',
    yonghu:'pengguna',
    danlunjiaoyi:'transaksi putaran tunggal',
    caozuo:'beroperasi',
    pljoin:'Berhasil bergabung dengan ruang obrolan',
    pltingshou:'Produk telah dihentikan',
    gengou:"Pembelian lanjutan",
	quedinggengou:"Apakah Anda yakin akan mengikuti pembelian tersebut?",
	wanjia:"pemain",
	leibie:"kategori",
	jine:"Jumlah",
    gouru:'pembelian',
    zanweigouru:'Belum dibeli',
    qigou:'Minimal pembelian',
    gourushuliang:'Kuantitas pembelian',
    changgui:'konvensional',
    shuzi:'nomor',
    yinglihezhi:'keuntungan dan nilai',
    shouyijieshao:'Pengenalan pendapatan',
    lijicunru:'Setor sekarang',
    kaihaojieguo:'Hasil pembukaan nomor',
    kaihaoqushi:'Tren pembukaan',
    chanpingshuoming:'Deskripsi Produk',
    qingchuhuancun:'bersihkan cache',
    youke:'turis',
	gongxi:'Selamat ',
	zai:' ada ',
	yingli:' Laba ',
	xiadanchenggong:'berhasil dipesan',
	jiazaizhong:'memuat...',
	guanbitg:'Tutup, hentikan pembelian',
	xiangoudl:'Anda hanya dapat mengikuti putaran pembelian saat ini',
	liaotiantip:'Sistem telah melarang Anda, silakan pilih untuk membeli',
	tishi:'petunjuk',
	zhidao:'tahu',
	zhuanchusb:'Transfer keluar gagal',
	zhuanchusbs:'Saldo tidak mencukupi, transfer gagal',
	buyxq:'Detail pembelian',
	orderNo:'Nomor tagihan',
	plan_name:'Informasi kamar',
	buyxx:'Informasi pembelian',
	haoxx:'Detail pemilihan nomor',
	buysj:'Waktu pembelian',
	yilou:'Kelalaian',
	fzjqb:'Disalin ke kertas karton',
	chanpin1:'Waktu pembukaan penjualan: satu putaran setiap 3,5 menit, dibuka pada pukul 10:00 hari itu dan ditutup pada pukul 02:00 keesokan harinya.',
	chanpin2:'Ada 3 angka dari 0 hingga 9 di setiap putaran. 3 angka tersebut ditambahkan ke hasil akhir. Anda dapat berinvestasi dalam panjang, pendek, tunggal, bipolar, anoda, katoda, dan angka.',
    service: 'layanan daring',
	Tips:{
        wjmm:'Jika Anda lupa kata sandi Anda, silakan hubungi layanan pelanggan untuk mengubahnya!',
        dlcg:'login berhasil',
        tjcg:'Berhasil dikirimkan',
        zanwu:'Tidak ada data',
        buyizhi:'Dua kata sandi tidak konsisten',
        zcbz:'Aset Anda tidak mencukupi, silakan setor',
        pltxje:'Silakan masukkan jumlah penarikan',
        zdtx:'Penarikan minimal 100',
		youke:'Pengunjung yang terhormat: Silakan mendaftar sebagai anggota resmi sebelum melanjutkan.',
		szcg:'Penyiapan berhasil',
    },
    plemail: 'silakan masukkan email Anda',
    plcode: 'silakan masukkan kode verifikasi',
    sendcode: 'Kirim kode verifikasi',
    username: 'nama belakang',
    email: 'Surat',
    changepic:'Mengubah avatar',
    rule1:'Aturannya adalah sebagai berikut',
    rule2:'Overflow: Jumlah nilai pada ketiga posisi lebih besar atau sama dengan 14,15,16,17,18,19,20,21,22,23,24,25,26,27.',
    rule3:'Catatan: Jumlah ketiga posisi tersebut kurang dari atau sama dengan 00,01,02,03,04,05,06,07,08,09,10,11,12,13',
    rule4:'Ganjil : Jumlah nilai pada ketiga posisi tersebut adalah bilangan ganjil.',
    rule5:'Genap: Jumlah nilai pada ketiga posisi tersebut adalah bilangan genap.',
    rule6:'Yiqi (jumlah tiga nilai): 15,17,19,21,23,25,27.',
    rule7:'Catatan ganjil (jumlah tiga nilai): 01,03,05,07,09,11,13.',
    rule8:'Overflow (jumlah tiga nilai): 14,16,18,20,22,24,26.',
    rule9:'Catatan (jumlah tiga nilai): 00,02,04,06,08,10,12',
    rule10:'instruksi lainnya',
    rule11:'1. Peluang di atas sudah termasuk pokok.',
    rule12:'2. Semua jumlah format hanya akan valid setelah dimasukkan, jika tidak, sistem tidak akan mengenalinya.',
    rule13:'3. Jika terjadi kegagalan platform, masalah jaringan, atau hilangnya hadiah, semua pembelian yang dilakukan oleh semua orang akan menjadi tidak valid, dan semua pokok akan dikembalikan setelah sistem dipulihkan.',
    yigoumai:'dibeli',
    forgetpassword:'Ambil kata sandi',
    fanhuidenglu:'Kembali ke halaman login',
    fenhanghaoma:'nomor cabang',
    plsub:'Silakan masukkan nomor cabang',
    plsubname:'Silakan masukkan nama cabang',
    fendianmingcheng:'Nama cabang',
    '最低購入100':'Minimal pembelian 100',
    betall:'Semua masuk',
    yiqiehuan:'Beralih ke',
    '有未處理的請求 請聯係客服':'Jika ada permintaan yang belum diproses, silakan menghubungi layanan pelanggan',
    '體現信息錯誤': 'Kesalahan informasi penarikan',
    '成功': 'success',
    '失败': 'fail',
    'touxiang1': 'Change avatar',
    'xitongtouxiang': 'System avatar',
    'album': 'Pilih dari album',
    'Photograph': 'Foto',
    'moren': 'Gambar default sistem',
    'xiaochu': 'Membatalkan',
    'cun2': 'menyimpan',
    all:'semua',
}
export default ind;
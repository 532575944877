const en={
    jiantizhongwen:'English',
    xianlu:'Line ',
    dianwei:'Point',
    wodezichan:'My assets',
    touzikuaixun:'Investment News',
    jiaoyiyonghu:'User',
    jiaoyipinglei:'Category',
    danlunhuoli:'Profit',
    jiaoyiriqi:'Date',
    dengluhouchakan:'View after login',
    shouye:'Home',
    jiaoyidating:'hall',
    yucun:'Prestore',
    zoushi:'Trend',
    wode:'Mine',
    zuiditouzi:'Minimum investment',
    zuidijinru:'Minimum entry',
    jichulicai:'Basic',
    zishenlicai:'Senior',
    dashilicai:'Master',
    zhuanjialicai:'Expert',
	基础理财:'Basic',
    资深理财:'Senior',
    大师理财:'Master',
    专家理财:'Expert',
	基础:'Basic',
    资深:'Senior',
    大师:'Master',
    专家:'Expert',
    huiyuanquanyi:'Member benefits',
    zuoriyingli:'Profit yesterday',
    zongzichan:'Total assets',
    chucunjin:'Money',
    jinriyingli:'Profit today',
    zhuanchu:'Withdraw',
    cunru:'Deposit',
    zhanghuxinxni:'Account information',
    lishizhangdan:'Historical bills',
    zijinminxi:'Funding Details',
    renwuzhongxin:'Mission center',
    xiaoxigonggao:'News announcement',
    tuijianfenxiang:'Recommended to share',
    huodongzhongxin:'Activity Center',
    jibenziliao:'Basic information',
    dengluzhanghao:'Login account',
    nicheng:'Nick name',
    shezhi:'Settings',
    genggaixianlu:'Change line',
    guanyuwomen:'About us',
    xitongshezhi:'System settings',
    yuyanshezhi:'Language settings',
    tuichudenglu:'Logout',
    bangdingyinhangka:'Bind bank card',
    bangdingxuniqianbao:'Bind virtual wallet',
    anquanma:'Security code',
    xiugailenglumima:'Modify login password',
    yinhangkaguanli:'Bank card management',
    tianjiayinhangka:'Add a bank card',
    xuniqianbaoguanli:'Virtual wallet management',
    tianjiaxuniqianbao:'Add virtual wallet',
    chiakren:'Cardholder',
    yinhangkakahao:'Bank card number',
    yinhangmingcheng:'Bank name',
    yhkTps:'Please bind the cardholder own bank card',
    nixiqianbaodizhi:'Wallet address',
    xuniqianbaoleixing:'Wallet Type',
    xnqbTps:' Reminder: Please fill in the USDT address type, such as: trc20, erc20, omni. ',
    plchikaren:'Please enter cardholder',
    plkahao:'Please enter cardholder card number',
    plyinhangmingcheng:'Please enter bank name',
    plqbdz:'Please enter the virtual wallet address',
    plqblx:'Please enter virtual wallet type',
    shezhianquanma:'Set security code',
    yuananquanma:'Original security code',
    xinanquanma:'New security code',
    planquanma:'Please enter the original security code',
    plxinanquanma:'Please enter a new security code',
    queren:'Confirm',
	quxiao:'Cancel',
    anquanmaTps:' Please set a security code, not the same as the bank card password',
    anquanmaTpsTow:' Dear customer, this security code is the password when you transfer out. For security reasons, please try not to be the same as the login password',
    xiugaidenglu:'Modify login password',
    jiumima:'Old Password',
    xinmima:'New password',
    querenmima:'Confirm Password',
    pljiumima:'Please enter old password',
    plxinmima:'Please enter a new password',
    plquerenmima:'Please enter the password again',
    wancheng:'Complete',
    kaishitouzi:'Start investing',
    kaiTps:'When the current line is unavailable, try switching to other lines',
    zuiyou:'Optimal',
    dangaqianxianlu:'Current line',
    dangqianbanben:'Current version',
    banquansuoyou:'Copyright',
    yinglizhendong:'Earnings shock',
    gensuixitong:'Default',
    zhuanchujine:'Amount',
    plzhuanchu:'Please enter the transfer amount',
    zhuanchuTps:'Tips: If you have any questions, please contact the platform customer service.',
    xuanzeyh:'Please select a bank card for withdrawal',
    xuanzehb:'Please select a virtual wallet for withdrawal',
    tijiaoshenqing:'Submit application',
    shuruanquanma:'Please enter security code',
    xianshangcunru:'Online deposit',
    saomacunru:'USDT',
    wangyincunru:"Banking deposit",
    shijian:'Time',
    upshijian:'Starting time',
    downshijian:'End Time',
    wuTps:'No data ~',
    jiazaicx:'Reload',
    di:'Period ',
    lun:' ',
    weiyingli:'Unprofitable',
    yishouli:'Accepted',
    zhuangtai:'Status',
    chongzhi:'Reset',
    riqi:'Date',
    CapActive:{
        chongzhi:'Recharge',
        tixian:'Withdraw',
        goumai:'Buy',
        yingli:'Profit',
        zengsong:'Give away',
        weitongguo:'Did not pass',
        yitongguo:'Passed',
        yijujue:'Rejected',
    },
    zhanneixiaoxi:"Information",
    pingtaigonggao:'Announcement',
    fuzhilanjie:"copy Link",
    denglu:'Login',
    zhuce:'Register',
    jizhumima:'Remember',
    wangjimima:'Forgot password',
    youkedenglu:'Visitor login',
    zhucezhanghao:"Register account",
    plusername:'Please enter user name',
    plpassword:'Please enter password',
    wanchengzhuce:'Complete',
    yiyouzhanghao:'Have an account',
    qingdenglu:'Please sign in',
    remenhuati:'Hot topic',
    news1:'Original',
    news2:'Recommend',
    news3:'Asset',
    renliulan:' browse',
    hezhi:'And value',
    lunshu:'Period',

    做多:'Long',
    做空:'Short',
    多单:'Long Single',
    多双:'Long Double',
    空单:'Short Single',
    空双:'Short Double',
    平单:'Flat Single',
    平双:'Flat Double',
	极阴:'Cathode',
	极阳:'Anode',
    qi:'',
    juli:'distance',
    lunjiezhi:'end',
    yifengpan:'Stop betting',
    yiguanbi:'Closed',
    yonghu:'User',
    danlunjiaoyi:'Trade',
    caozuo:'Operate',
    pljoin:'Join the chat room successfully',
    pltingshou:'Product has been discontinued',
    gengou:"Follow",
	quedinggengou:"Are you sure to follow the purchase?",
	wanjia:"player",
	leibie:"category",
	jine:"amount",
    gouru:'Buy',
    zanweigouru:'Not yet purchased',
    qigou:' purchase',
    gourushuliang:'Purchase quantity',
    changgui:'Conventional',
    shuzi:'Number',
    yinglihezhi:'Profit and value',
    shouyijieshao:'Income',
    lijicunru:'Deposit',
    kaihaojieguo:'Record',
    kaihaoqushi:'Trend',
    chanpingshuoming:'Description',
    qingchuhuancun:'clear cache',
    youke:'Visitor',
	gongxi:'Congratulations to ',
	zai:' in the ',
	yingli:' profit ',
	xiadanchenggong:'successfully ordered',
	jiazaizhong:'Loading...',
	guanbitg:'closed, stop buying',
	xiangoudl:'Only follow the current round',
	liaotiantip:'The system has been banned, please choose to buy',
	tishi:'Tip',
	zhidao:'OK',
	zhuanchusb:'Failed to transfer out',
	zhuanchusbs:'Insufficient balance, transfer out failed',
	buyxq:'Purchase details',
	orderNo:'Bill No.',
	plan_name:'Hall information',
	buyxx:'Buy information',
	haoxx:'Number selection details',
	buysj:'Buy time',
	yilou: 'missing',
	fzjqb: 'Copied to clipboard',
	chanpin1: 'Sale time: every 3.5 minutes is a round, opening at 10:00AM on the same day and closing at 02:00AM the next day. ',
	chanpin2: 'There are 3 numbers from 0 to 9 in each round. The sum of the 3 numbers is the final result. You can invest in the result: Many, Short, Flat Single, Flat Double, Many Single, Many Double, Short Single, Short Double, Cathode, Anode, and number. ',
    service: 'online service',
	Tips:{
        wjmm: 'Forgot your password, please contact customer service to modify it!',
        dlcg: 'Login successful',
        tjcg: 'Submitted successfully',
        zanwu: 'No data',
        buyizhi: 'The two passwords are inconsistent',
        zcbz: 'Your assets are insufficient, please deposit',
        pltxje: 'Please enter the withdrawal amount',
        zdtx: 'Minimum withdrawal 100',
		youke: 'Dear tourists: Please register as a full member and operate. ',
		szcg: 'set successfully',
     },
     plemail: 'Please enter email',
     plcode: 'Please enter code',
     sendcode: 'send code',
     username: 'username',
     email: 'email',
     changepic:'Change avatar',
     rule1:'The rules are as follows',
    rule2:'Long: The sum of the values   in the three positions is greater than or equal to 14,15,16,17,18,19,20,21,22,23,24,25,26,27.',
    rule3:'Short: The sum of the three positions is less than or equal to 00,01,02,03,04,05,06,07,08,09,10,11,12,13',
    rule4:'Flat Single: The sum of the values   in the three positions is an odd number.',
    rule5:'Flat Double: The sum of the values   in the three positions is an even number.',
    rule6:'Long Single (sum of three values): 15,17,19,21,23,25,27.',
    rule7:'Short Single (sum of three values): 01,03,05,07,09,11,13.',
    rule8:'Long Double (sum of three values): 14,16,18,20,22,24,26.',
    rule9:'Short Double  (sum of three values): 00,02,04,06,08,10,12',
    rule10:'Other instructions',
    rule11:'1. The above odds include principal.',
    rule12:'2. All format amounts are valid only after input, otherwise the system cannot recognize them.',
    rule13:'3. In the event of platform failure, network problems, or no winnings, all purchases made by everyone will be invalid, and all principal will be refunded after the system is restored.',
     yigoumai:'bought',
     forgetpassword:'Retrieve password',
     fanhuidenglu:'Return to login page',
     fenhanghaoma:'branch number',
     plsub:'Please enter branch number',
     plsubname:'Please enter the branch name',
     fendianmingcheng:'Branch name',
     '最低購入100':'Minimum purchase 100',
     betall:'bet all',
     yiqiehuan:'Switched to',
     '有未處理的請求 請聯係客服':'If there are unprocessed requests, please contact customer service',
    '體現信息錯誤': 'Withdrawal information error',
    '成功': 'success',
    '失败': 'fail',
    'touxiang1': 'Change avatar',
    'xitongtouxiang': 'System avatar',
    'album': 'Select from album',
    'Photograph': 'Photograph',
    'moren': 'System default picture',
    'xiaochu': 'Cancel',
    'cun2': 'save',
     all:'all',
}
export default en;
import { createI18n } from 'vue-i18n'
import jp from './config/jp'
import tw from './config/tw'
import en from './config/en'
import kr from './config/kr'
import vi from './config/vi'
import ind from './config/ind'
console.log(createI18n)
const i18n  = createI18n({
    legacy: false, // composition API
    globalInjection:true,
    locale:localStorage.getItem('locale')||'en',
    messages:{
		en,
        tw,
        jp,
        kr,
        vi,
        ind
    },
 })
 export default i18n;
<template>
  <div class="language">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
          <div class="left" @click="changego">
            <van-icon name="arrow-left" color="#fff" size="20px" />
          </div>
          <div class="middle fs-18">{{ $t("yuyanshezhi") }}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
        <div class="g-content">
          <ul class="lang-box">
            <li
              class="lang"
              :class="{ active: show == item.type }"
              @click="(show = item.type), languageSet(item.type)"
              v-for="item in languages"
              :key="item.type"
            >
              <span>{{item.title}}</span>
              <img
                src="@/assets/image/gou.png"
                class="icon"
                alt=""
                v-if="show == item.type"
              />
            </li>
          </ul>
        </div>
      </header>
    </div>
  </div>
</template>
<script>
export default {
  name: "Language",
  components: {},
  data() {
    return {
      checked: false,
      show: 0,
      languages:[
        {
          'type':0,
          'title':this.$t("gensuixitong")
        },
        {
          'type':2,
          'title':'中文繁体'
        },
        {
          'type':3,
          'title':'English'
        },
        {
          'type':4,
          'title':'日本語'
        },
        {
          'type':5,
          'title':'한국인'
        },
        {
          'type':6,
          'title':'Tiếng Việt'
        },
        {
          'type':7,
          'title':'bahasa Indonesia'
        }
      ]
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
    languageSet(type) {
      if (type == 0) {
        localStorage.setItem("locale", "en");
      } else if (type == 1) {
        localStorage.setItem("locale", "zh");
      } else if (type == 2) {
        localStorage.setItem("locale", "tw");
      } else if (type == 3) {
        localStorage.setItem("locale", "en");
      } else if (type == 4) {
        localStorage.setItem("locale", "jp");
      } else if (type == 5) {
        localStorage.setItem("locale", "kr");
      } else if (type == 6) {
        localStorage.setItem("locale", "vi");
      } else if (type == 7) {
        localStorage.setItem("locale", "ind");
      }
      localStorage.setItem("localetype", type);
      this.show = type;
      location.reload();
    },
  },
  created() {
    if (localStorage.getItem("localetype")) {
      this.show = localStorage.getItem("localetype");
    } else [(this.show = 0)];
  },
};
</script>
<style lang="less" scoped>
.g-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: scroll;
  text-align: center;
  color: #a0a0a0;
}
.lang-box {
}
.lang {
  margin-left: 0.4rem;
  padding-right: 0.4rem;
  display: flex;
  height: 1.18rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 0.4rem;
  border-bottom: 1px solid #3d5179;
}

.active {
  color: green;
}
</style>
const kr={
	jiantizhongwen:'한국인',
    xianlu:'선',
    dianwei:'현재 지점',
    wodezichan:'내 자산',
    touzikuaixun:'투자뉴스',
    jiaoyiyonghu:'거래 사용자',
    jiaoyipinglei:'거래 카테고리',
    danlunhuoli:'한 라운드의 이익',
    jiaoyiriqi:'거래 날짜',
    dengluhouchakan:'로그인 후 보기',
    shouye:'첫 장',
    jiaoyidating:'거래소',
    yucun:'사전 저장',
    zoushi:'경향',
    wode:'내 거',
    zuiditouzi:'최소 투자',
    zuidijinru:'최소 입장',
    jichulicai:'베이스',
    zishenlicai:'상위',
    dashilicai:'그랜드마스터',
    zhuanjialicai:'전문가',
	基础理财:'베이스',
    资深理财:'상위',
    大师理财:'그랜드마스터',
    专家理财:'전문가',
	基础:'베이스',
    资深:'상위',
    大师:'그랜드마스터',
    专家:'전문가',
    huiyuanquanyi:'회원 혜택',
    zuoriyingli:'어제 수익',
    zongzichan:'총 자산',
    chucunjin:'보증금',
    jinriyingli:'오늘의 수익',
    zhuanchu:'다른 곳으로 이전하다',
    cunru:'보증금',
    zhanghuxinxni:'계정 정보',
    lishizhangdan:'역사적 법안',
    zijinminxi:'기금 세부정보',
    renwuzhongxin:'선교 센터',
    xiaoxigonggao:'뉴스 발표',
    tuijianfenxiang:'공유 추천',
    huodongzhongxin:'활동 센터',
    jibenziliao:'기본 정보',
    dengluzhanghao:'로그인 계정',
    nicheng:'닉네임',
    shezhi:'설정',
    genggaixianlu:'경로 변경',
    guanyuwomen:'회사 소개',
    xitongshezhi:'환경 설정',
    yuyanshezhi:'언어 설정',
    tuichudenglu:'로그아웃',
    bangdingyinhangka:'은행 카드 바인딩',
    bangdingxuniqianbao:'가상 지갑 바인딩',
    anquanma:'보안 코드',
    xiugailenglumima:'로그인 비밀번호 변경',
    yinhangkaguanli:'은행 카드 관리',
    tianjiayinhangka:'은행 카드 추가',
    xuniqianbaoguanli:'가상 지갑 관리',
    tianjiaxuniqianbao:'가상 지갑 추가',
    chiakren:'카드홀더',
    yinhangkakahao:'은행 카드 번호',
    yinhangmingcheng:'은행 이름',
    yhkTps:'카드 소지자의 은행카드를 묶어주세요.',
    nixiqianbaodizhi:'가상 지갑 주소',
    xuniqianbaoleixing:'가상 지갑 유형',
    xnqbTps:' 알림: trc20, erc20, omni와 같은 USDT 주소 유형을 입력하세요. ',
    plchikaren:'카드 소지자를 입력하세요.',
    plkahao:'카드 소지자의 카드번호를 입력해주세요.',
    plyinhangmingcheng:'은행명을 입력해주세요',
    plqbdz:'가상 지갑 주소를 입력해주세요',
    plqblx:'가상 지갑 유형을 입력하세요.',
    shezhianquanma:'보안 코드 설정',
    yuananquanma:'원래 보안 코드',
    xinanquanma:'새로운 보안 코드',
    planquanma:'원래 보안 코드를 입력하세요',
    plxinanquanma:'새로운 보안 코드를 입력해주세요',
    queren:'확인하다',
	quxiao:'취소',
    anquanmaTps:' 은행 카드 비밀번호와 동일해서는 안되는 보안 코드를 설정하십시오.',
    anquanmaTpsTow:' 고객님, 본 보안코드는 이체시 비밀번호로 사용되며, 보안상의 이유로 로그인 비밀번호와 동일하지 않도록 주의하시기 바랍니다.',
    xiugaidenglu:'로그인 비밀번호 변경',
    jiumima:'기존 비밀번호',
    xinmima:'새 비밀번호',
    querenmima:'비밀번호 확인',
    pljiumima:'이전 비밀번호를 입력해주세요',
    plxinmima:'새로운 비밀번호를 입력해주세요',
    plquerenmima:'비밀번호를 다시 입력해주세요',
    wancheng:'마치다',
    kaishitouzi:'투자 시작',
    kaiTps:'현재 회선을 사용할 수 없으면 다른 회선으로 전환해 보세요.',
    zuiyou:'최적의',
    dangaqianxianlu:'현재 라인',
    dangqianbanben:'현재 버전',
    banquansuoyou:'판권 소유',
    yinglizhendong:'어닝쇼크',
    gensuixitong:'시스템을 따르세요',
    zhuanchujine:'이체금액',
    plzhuanchu:'이체금액을 입력해주세요',
    zhuanchuTps:'알림: 질문이 있는 경우 플랫폼 고객 서비스에 문의하세요.',
    xuanzeyh:'출금 은행 카드를 선택하세요',
    xuanzehb:'출금할 가상 지갑을 선택해주세요',
    tijiaoshenqing:'지원서 제출',
    shuruanquanma:'보안코드를 입력해주세요',
    xianshangcunru:'온라인 입금',
    saomacunru:'USDT',
    wangyincunru:"은행 예금",
    shijian:'시간',
    upshijian:'시작 시간',
    downshijian:'종료 시간',
    wuTps:'아직 관련자료가 없습니다~',
    jiazaicx:'새로고침',
    di:'아니요.',
    lun:'바퀴',
    weiyingli:'수익성이 없음',
    yishouli:'수락됨',
    zhuangtai:'상태',
    chongzhi:'초기화',
    riqi:'날짜',
    CapActive:{
        chongzhi:'충전하다',
        tixian:'철회하다',
        goumai:'구입하다',
        yingli:'이익',
        zengsong:'포기하다',
        weitongguo:'통과하지 못함',
        yitongguo:'합격',
        yijujue:'거부됨',
    },
    zhanneixiaoxi:"방송국 뉴스",
    pingtaigonggao:'플랫폼 발표',
    fuzhilanjie:"링크 복사",
    denglu:'로그인',
    zhuce:'등록하다',
    jizhumima:'비밀번호 기억하기',
    wangjimima:'비밀번호를 잊어버려',
    youkedenglu:'방문자 로그인',
    zhucezhanghao:"계정 등록",
    plusername:'사용자 이름을 입력하세요',
    plpassword:'비밀번호를 입력 해주세요',
    wanchengzhuce:'등록 완료',
    yiyouzhanghao:'이미 계정이 있습니다',
    qingdenglu:'로그인 해주세요',
    remenhuati:'화제',
    news1:'원래의',
    news2:'추천하다',
    news3:'재산',
    renliulan:'사람들이 찾아보기',
    hezhi:'합계 값',
    lunshu:'라운드 수',
    做多:'초과 가치',
    做空:'메모 값',
    多单:'이치',
    多双:'과다',
    空单:'이상함을 주목하라',
    空双:'참고라도',
    平单:'이상한 값',
    平双:'균등한 가치',
	极阴:'극도로 음',
	极阳:'매우 양',
    qi:'예상하다',
    juli:'거리',
    lunjiezhi:'라운드 종료',
    yifengpan:'닫은',
    yiguanbi:'닫은',
    yonghu:'사용자',
    danlunjiaoyi:'단일 라운드 거래',
    caozuo:'작동하다',
    pljoin:'채팅방에 성공적으로 참여했습니다',
    pltingshou:'제품이 단종되었습니다',
    gengou:"후속 구매",
	quedinggengou:"구매를 따르시겠습니까?",
	wanjia:"플레이어",
	leibie:"범주",
	jine:"양",
    gouru:'구입',
    zanweigouru:'아직 구매하지 않음',
    qigou:'최소 구매',
    gourushuliang:'구매 수량',
    changgui:'전통적인',
    shuzi:'숫자',
    yinglihezhi:'이익과 가치',
    shouyijieshao:'소득 소개',
    lijicunru:'지금 입금하세요',
    kaihaojieguo:'번호 공개 결과',
    kaihaoqushi:'오프닝 트렌드',
    chanpingshuoming:'제품 설명',
    qingchuhuancun:'캐시 지우기',
    youke:'관광객',
	gongxi:'축하해요 ',
	zai:' 존재하다 ',
	yingli:' 이익 ',
	xiadanchenggong:'성공적으로 주문했습니다',
	jiazaizhong:'로드 중...',
	guanbitg:'휴무, 구매중단',
	xiangoudl:'현재 구매 라운드만 팔로우할 수 있습니다.',
	liaotiantip:'시스템에서 귀하를 차단했습니다. 구매를 선택하세요.',
	tishi:'힌트',
	zhidao:'알고 있었다',
	zhuanchusb:'전송 실패',
	zhuanchusbs:'잔액 부족, 이체 실패',
	buyxq:'구매내역',
	orderNo:'청구서 번호',
	plan_name:'객실정보',
	buyxx:'구매정보',
	haoxx:'번호 선택 세부정보',
	buysj:'구매시간',
	yilou:'생략',
	fzjqb:'임시보드에 복사됨',
	chanpin1:'영업 개시 시간 : 3.5분 간격으로 1회차, 당일 오전 10시에 개장하고 다음날 오전 2시에 마감합니다.',
	chanpin2:'각 라운드에는 0부터 9까지 3개의 숫자가 있으며, 3개의 숫자가 최종 결과에 추가되며 결과적으로 Long, Short, Single, 양극성, 양극, 음극 및 숫자에 투자할 수 있습니다.',
    service: '온라인 서비스',
	Tips:{
        wjmm:'비밀번호를 잊으셨다면 고객센터로 연락하셔서 비밀번호를 변경해주세요!',
        dlcg:'성공적 로그인',
        tjcg:'성공적으로 제출되었습니다',
        zanwu:'데이터 없음',
        buyizhi:'두 개의 비밀번호가 일치하지 않습니다',
        zcbz:'자산이 부족합니다. 입금해주세요',
        pltxje:'출금금액을 입력해주세요',
        zdtx:'최소 출금 100',
		youke:'방문자 여러분, 진행하기 전에 정식 회원으로 등록하시기 바랍니다.',
		szcg:'설정 성공',
    },
    plemail: '이메일을 입력해주세요',
    plcode: '인증번호를 입력해주세요',
    sendcode: '인증 코드 보내기',
    username: '사용자 이름',
    email: '우편',
    changepic:'아바타 변경',
    rule1:'규칙은 다음과 같습니다',
    rule2:'오버플로: 세 위치의 값의 합이 14,15,16,17,18,19,20,21,22,23,24,25,26,27보다 크거나 같습니다.',
    rule3:'참고: 세 위치의 합은 00,01,02,03,04,05,06,07,08,09,10,11,12,13보다 작거나 같습니다.',
    rule4:'홀수: 세 위치의 값의 합이 홀수입니다.',
    rule5:'짝수: 세 위치의 값의 합이 짝수입니다.',
    rule6:'Yiqi(세 가지 값의 합): 15,17,19,21,23,25,27.',
    rule7:'홀수(세 값의 합): 01,03,05,07,09,11,13.',
    rule8:'오버플로(세 값의 합): 14,16,18,20,22,24,26.',
    rule9:'참고(3개 값의 합): 00,02,04,06,08,10,12',
    rule10:'기타 지침',
    rule11:'1. 위 배당률에는 원금이 포함되어 있습니다.',
    rule12:'2. 모든 형식의 금액은 입력 후에만 유효합니다. 그렇지 않으면 시스템이 이를 인식하지 못합니다.',
    rule13:'3. 플랫폼 장애, 네트워크 문제 또는 경품 상실의 경우 모든 사람의 모든 구매는 무효화되며 시스템이 복원된 후 모든 원금이 환불됩니다.',
    yigoumai:'구입했다',
    forgetpassword:'비밀번호 검색',
    fanhuidenglu:'로그인 페이지로 돌아가기',
    fenhanghaoma:'지점 번호',
    plsub:'지점번호를 입력해주세요',
    plsubname:'지점명을 입력해주세요',
    fendianmingcheng:'지점명',
    '最低購入100':'최소 구매 100',
    betall:'모두',
    yiqiehuan:'다음으로 전환됨',
    '有未處理的請求 請聯係客服':'처리되지 않은 요청이 있는 경우 고객 서비스에 문의하세요.',
    '體現信息錯誤': '출금정보 오류',
    '成功': '성공',
    '失败': '실패하다',
    'touxiang1': '아바타 변경',
    'xitongtouxiang': '시스템 아바타',
    'album': '앨범에서 선택',
    'Photograph': '사진',
    'moren': '시스템 기본 그림',
    'xiaochu': '취소',
    'cun2': '구하다',
    all:'모두',
}
export default kr;
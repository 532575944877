<template>
  <div class="notice">
    <div class="settingwarp">
      <header>
        <div class="g-header" style="background: rgb(14, 21, 48)">
     <div class="left" @click="changego">
           <van-icon name="arrow-left" color="#fff" size='20px'/>
          </div>
          <div class="middle fs-18">{{$t('xiaoxigonggao')}}</div>
          <div class="right">
            <div class="bg-icon bg-icon-setting"></div>
          </div>
        </div>
        <div class="blockHeight"></div>
      </header>
      <div class="g-content">
        <van-tabs v-model="active" animated swipeable>
          <van-tab>
            <template #title>{{$t('zhanneixiaoxi')}} </template>
            <!-- 内容 -->
            <div class="warpnotice">
              <div class="warpnotice-content" v-html="zhannei?zhannei.content:$t('wuTps')"></div>
            </div>
          </van-tab>
          <van-tab>
            <template #title>{{$t('pingtaigonggao')}} </template>
            <!-- 内容 -->
            <div class="warpnotice">
              <div class="warpnotice-content" v-html="pingtai?pingtai.content:$t('wuTps')"></div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import homeApi from "@/api/home";
export default {
  name: "Notice",
  components: {},
  data() {
    return {
      active: 1,
      zhannei: null,
      pingtai: null
    };
  },
  methods: {
    changego(){
      this.$router.go(-1)
    },
    info() {
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .noticeList()
        .then((data) => {
          console.log(data.data);
          this.zhannei = data.data.zhannei;
          this.pingtai = data.data.pingtai;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
  },
  mounted() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.settingwarp {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.warpnotice {
  height: 100%;
}
.warpnotice-content{
  margin-top: 1.2rem;
  height: 16rem;
  overflow: auto;
}
</style>
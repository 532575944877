import store from "@/utils/store";

const userStore = {
  setToken(token) {
    store.set("token", token);
  },
  setSessionStorage(key, value) {
    store.set(key, value);
  },
  delSessionStorage(key) {
    store.delete(key);
  },
  getToken() {
    return store.get("token");
  },
  clearToken() {
    store.delete("token");
  }
};

export default userStore;

const vi={
	jiantizhongwen:'Tiếng Việt',
    xianlu:'đường kẻ',
    dianwei:'Điểm hiện tại',
    wodezichan:'tài sản của tôi',
    touzikuaixun:'Tin tức đầu tư',
    jiaoyiyonghu:'người dùng giao dịch',
    jiaoyipinglei:'Danh mục giao dịch',
    danlunhuoli:'Lợi nhuận trong một vòng',
    jiaoyiriqi:'Ngày Giao dịch',
    dengluhouchakan:'Xem sau khi đăng nhập',
    shouye:'trang đầu',
    jiaoyidating:'sàn giao dịch',
    yucun:'Lưu trước',
    zoushi:'xu hướng',
    wode:'của tôi',
    zuiditouzi:'Đầu tư tối thiểu',
    zuidijinru:'mục nhập tối thiểu',
    jichulicai:'Căn cứ',
    zishenlicai:'Cao cấp',
    dashilicai:'Trưởng sư',
    zhuanjialicai:'chuyên gia',
	基础理财:'Căn cứ',
    资深理财:'Cao cấp',
    大师理财:'Trưởng sư',
    专家理财:'chuyên gia',
	基础:'Căn cứ',
    资深:'Cao cấp',
    大师:'Trưởng sư',
    专家:'chuyên gia',
    huiyuanquanyi:'lợi ích thành viên',
    zuoriyingli:'Lợi nhuận của ngày hôm qua',
    zongzichan:'Tổng tài sản',
    chucunjin:'tiền gửi',
    jinriyingli:'Lợi nhuận hôm nay',
    zhuanchu:'chuyển ra ngoài',
    cunru:'Tiền gửi',
    zhanghuxinxni:'thông tin tài khoản',
    lishizhangdan:'Dự luật lịch sử',
    zijinminxi:'Chi tiết quỹ',
    renwuzhongxin:'trung tâm truyền giáo',
    xiaoxigonggao:'Thông báo tin tức',
    tuijianfenxiang:'Đề xuất chia sẻ',
    huodongzhongxin:'Trung tâm hoạt động',
    jibenziliao:'thông tin cơ bản',
    dengluzhanghao:'Đăng nhập tài khoản',
    nicheng:'tên nick',
    shezhi:'cài đặt',
    genggaixianlu:'Thay đổi tuyến đường',
    guanyuwomen:'về chúng tôi',
    xitongshezhi:'Cài đặt hệ thống',
    yuyanshezhi:'cài đặt ngôn ngữ',
    tuichudenglu:'đăng xuất',
    bangdingyinhangka:'Ràng buộc thẻ ngân hàng',
    bangdingxuniqianbao:'Liên kết ví ảo',
    anquanma:'mã bảo mật',
    xiugailenglumima:'Thay đổi mật khẩu đăng nhập',
    yinhangkaguanli:'Quản lý thẻ ngân hàng',
    tianjiayinhangka:'Thêm thẻ ngân hàng',
    xuniqianbaoguanli:'Quản lý ví ảo',
    tianjiaxuniqianbao:'Thêm ví ảo',
    chiakren:'chủ thẻ',
    yinhangkakahao:'Số thẻ ngân hàng',
    yinhangmingcheng:'tên ngân hàng',
    yhkTps:'Vui lòng ràng buộc thẻ ngân hàng của chủ thẻ',
    nixiqianbaodizhi:'Địa chỉ ví ảo',
    xuniqianbaoleixing:'Loại ví ảo',
    xnqbTps:' Lời nhắc ấm áp: Vui lòng điền loại địa chỉ USDT, chẳng hạn như: trc20, erc20, omni ',
    plchikaren:'Vui lòng nhập chủ thẻ',
    plkahao:'Vui lòng nhập số thẻ của chủ thẻ',
    plyinhangmingcheng:'Vui lòng nhập tên ngân hàng',
    plqbdz:'Vui lòng nhập địa chỉ ví ảo',
    plqblx:'Vui lòng nhập loại ví ảo',
    shezhianquanma:'Đặt mã bảo mật',
    yuananquanma:'Mã bảo mật gốc',
    xinanquanma:'Mã bảo mật mới',
    planquanma:'Vui lòng nhập mã bảo mật gốc',
    plxinanquanma:'Vui lòng nhập mã bảo mật mới',
    queren:'xác nhận',
	quxiao:'Hủy bỏ',
    anquanmaTps:' Vui lòng đặt mã bảo mật, không được trùng với mật khẩu thẻ ngân hàng.',
    anquanmaTpsTow:' Kính gửi quý khách hàng, mã bảo mật này chính là mật khẩu của quý khách khi chuyển khoản, vì lý do bảo mật, vui lòng cố gắng không trùng với mật khẩu đăng nhập của mình.',
    xiugaidenglu:'Thay đổi mật khẩu đăng nhập',
    jiumima:'Mật khẩu cũ',
    xinmima:'mật khẩu mới',
    querenmima:'Xác nhận mật khẩu',
    pljiumima:'Vui lòng nhập mật khẩu cũ',
    plxinmima:'Vui lòng nhập mật khẩu mới',
    plquerenmima:'Vui lòng nhập lại mật khẩu',
    wancheng:'Hoàn thành',
    kaishitouzi:'Bắt đầu đầu tư',
    kaiTps:'Khi đường dây hiện tại không khả dụng, hãy thử chuyển sang đường dây khác',
    zuiyou:'tối ưu',
    dangaqianxianlu:'Dòng hiện tại',
    dangqianbanben:'Phiên bản hiện tại',
    banquansuoyou:'Đã đăng ký Bản quyền',
    yinglizhendong:'Cú sốc thu nhập',
    gensuixitong:'Theo dõi hệ thống',
    zhuanchujine:'Số tiền chuyển khoản',
    plzhuanchu:'Vui lòng nhập số tiền chuyển',
    zhuanchuTps:'Lời nhắc nhở nồng nhiệt: Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận dịch vụ khách hàng của nền tảng.',
    xuanzeyh:'Vui lòng chọn thẻ ngân hàng rút tiền',
    xuanzehb:'Vui lòng chọn ví ảo để rút tiền',
    tijiaoshenqing:'nộp đơn',
    shuruanquanma:'Vui lòng nhập mã bảo mật',
    xianshangcunru:'Gửi tiền trực tuyến',
    saomacunru:'USDT',
    wangyincunru:"tiền gửi ngân hàng",
    shijian:'thời gian',
    upshijian:'Thời gian bắt đầu',
    downshijian:'Thời gian kết thúc',
    wuTps:'Chưa có dữ liệu liên quan~',
    jiazaicx:'Tải lại',
    di:'KHÔNG.',
    lun:'bánh xe',
    weiyingli:'Không có lợi nhuận',
    yishouli:'Đã được chấp nhận',
    zhuangtai:'tình trạng',
    chongzhi:'cài lại',
    riqi:'ngày',
    CapActive:{
        chongzhi:'nạp tiền',
        tixian:'rút',
        goumai:'Mua',
        yingli:'lợi nhuận',
        zengsong:'phát phần thưởng',
        weitongguo:'Không vượt qua',
        yitongguo:'đi qua',
        yijujue:'vật bị loại bỏ',
    },
    zhanneixiaoxi:"tin tức đài",
    pingtaigonggao:'Thông báo nền tảng',
    fuzhilanjie:"sao chép đường dẫn",
    denglu:'Đăng nhập',
    zhuce:'đăng ký',
    jizhumima:'Nhớ mật khẩu',
    wangjimima:'quên mật khẩu',
    youkedenglu:'Đăng nhập của khách truy cập',
    zhucezhanghao:"đăng ký tài khoản",
    plusername:'Vui lòng nhập tên người dùng',
    plpassword:'Xin vui lòng nhập mật khẩu',
    wanchengzhuce:'Hoàn tất đăng ký',
    yiyouzhanghao:'Bạn co săn san để tạo một tai khoản',
    qingdenglu:'làm ơn đăng nhập',
    remenhuati:'chủ đề nóng',
    news1:'Nguyên bản',
    news2:'gợi ý',
    news3:'sự giàu có',
    renliulan:'Mọi người duyệt',
    hezhi:'giá trị tổng hợp',
    lunshu:'Số vòng',
    做多:'giá trị vượt quá',
    做空:'Giá trị ghi chú',
    多单:'nghĩa khí',
    多双:'tràn ra',
    空单:'Lưu ý sự kỳ lạ',
    空双:'Chú ý thậm chí',
    平单:'giá trị lẻ',
    平双:'giá trị chẵn',
	极阴:'Cực Âm',
	极阳:'Cực dương',
    qi:'Trông chờ',
    juli:'khoảng cách',
    lunjiezhi:'Kết thúc vòng đấu',
    yifengpan:'Đã đóng',
    yiguanbi:'đóng cửa',
    yonghu:'người dùng',
    danlunjiaoyi:'giao dịch một vòng',
    caozuo:'vận hành',
    pljoin:'Đã tham gia phòng trò chuyện thành công',
    pltingshou:'Sản phẩm đã bị ngừng sản xuất',
    gengou:"Mua hàng tiếp theo",
	quedinggengou:"Bạn có chắc chắn theo dõi việc mua hàng?",
	wanjia:"người chơi",
	leibie:"loại",
	jine:"Số lượng",
    gouru:'mua',
    zanweigouru:'Chưa mua',
    qigou:'mua tối thiểu',
    gourushuliang:'Số lượng mua',
    changgui:'thông thường',
    shuzi:'con số',
    yinglihezhi:'lợi nhuận và giá trị',
    shouyijieshao:'Giới thiệu thu nhập',
    lijicunru:'Gửi tiền ngay bây giờ',
    kaihaojieguo:'Kết quả mở số',
    kaihaoqushi:'Xu hướng mở cửa',
    chanpingshuoming:'Mô tả Sản phẩm',
    qingchuhuancun:'xóa bộ nhớ đệm',
    youke:'khách du lịch',
	gongxi:'Chúc mừng ',
	zai:' hiện hữu ',
	yingli:' Lợi nhuận ',
	xiadanchenggong:'đặt hàng thành công',
	jiazaizhong:'đang tải...',
	guanbitg:'Đóng cửa, ngừng mua hàng',
	xiangoudl:'Bạn chỉ có thể theo dõi đợt mua hàng hiện tại',
	liaotiantip:'Hệ thống đã cấm bạn, vui lòng chọn mua',
	tishi:'gợi ý',
	zhidao:'biết',
	zhuanchusb:'Chuyển đi không thành công',
	zhuanchusbs:'Số dư không đủ, chuyển khoản không thành công',
	buyxq:'Chi tiết mua hàng',
	orderNo:'Số hóa đơn',
	plan_name:'Thông tin phòng',
	buyxx:'Mua thông tin',
	haoxx:'Chi tiết lựa chọn số',
	buysj:'Thời gian mua hàng',
	yilou:'Bỏ sót',
	fzjqb:'Đã sao chép vào bảng dán',
	chanpin1:'Thời gian mở bán: 3,5 phút 1 đợt, mở lúc 10h00 ngày hôm đó và kết thúc lúc 02h00 ngày hôm sau.',
	chanpin2:'Mỗi vòng có 3 số từ 0 đến 9, 3 số đó được cộng vào kết quả cuối cùng, bạn có thể đầu tư dài, ngắn, đơn, lưỡng cực, cực dương, cực âm và số.',
    service: 'dịch vụ trực tuyến',
	Tips:{
        wjmm:'Nếu bạn quên mật khẩu, vui lòng liên hệ bộ phận chăm sóc khách hàng để thay đổi!',
        dlcg:'đăng nhập thành công',
        tjcg:'gửi thành công',
        zanwu:'Không có dữ liệu',
        buyizhi:'Hai mật khẩu không nhất quán',
        zcbz:'Tài sản của bạn không đủ, vui lòng gửi tiền',
        pltxje:'Vui lòng nhập số tiền rút',
        zdtx:'Rút tối thiểu 100',
		youke:'Kính gửi quý khách: Vui lòng đăng ký làm thành viên chính thức trước khi tiếp tục.',
		szcg:'Thiết lập thành công',
    },
    plemail: 'vui lòng nhập email của bạn',
    plcode: 'vui lòng nhập mã xác nhận',
    sendcode: 'Gửi mã xác minh',
    username: 'tên tài khoản',
    email: 'Thư',
    changepic:'Thay đổi hình đại diện',
    rule1:'Luật như sau',
    rule2:'Tràn: Tổng các giá trị ở ba vị trí lớn hơn hoặc bằng 14,15,16,17,18,19,20,21,22,23,24,25,26,27.',
    rule3:'Lưu ý: Tổng 3 vị trí nhỏ hơn hoặc bằng 00,01,02,03,04,05,06,07,08,09,10,11,12,13',
    rule4:'Lẻ: Tổng các giá trị ở 3 vị trí là số lẻ.',
    rule5:'Chẵn: Tổng các giá trị ở 3 vị trí là số chẵn.',
    rule6:'Yiqi (tổng của ba giá trị): 15,17,19,21,23,25,27.',
    rule7:'Lưu ý số lẻ (tổng của ba giá trị): 01,03,05,07,09,11,13.',
    rule8:'Tràn (tổng ba giá trị): 14,16,18,20,22,24,26.',
    rule9:'Lưu ý (tổng của ba giá trị): 00,02,04,06,08,10,12',
    rule10:'hướng dẫn khác',
    rule11:'1. Tỷ lệ cược trên đã bao gồm tiền gốc.',
    rule12:'2. Tất cả số tiền định dạng sẽ chỉ có hiệu lực sau khi nhập, nếu không hệ thống sẽ không nhận dạng được.',
    rule13:'3. Trong trường hợp nền tảng bị lỗi, sự cố mạng hoặc mất giải thưởng, mọi giao dịch mua do mọi người thực hiện sẽ không hợp lệ và toàn bộ tiền gốc sẽ được hoàn trả sau khi hệ thống được khôi phục.',
    yigoumai:'mua',
    forgetpassword:'Lấy lại mật khẩu',
    fanhuidenglu:'Quay lại trang đăng nhập',
    fenhanghaoma:'số chi nhánh',
    plsub:'Vui lòng nhập số chi nhánh',
    plsubname:'Vui lòng nhập tên chi nhánh',
    fendianmingcheng:'Tên chi nhánh',
    '最低購入100':'Mua tối thiểu 100',
    betall:'Tất cả trong',
    yiqiehuan:'Chuyển sang',
    '有未處理的請求 請聯係客服':'Nếu có yêu cầu chưa được xử lý, vui lòng liên hệ bộ phận chăm sóc khách hàng',
    '體現信息錯誤': 'Lỗi thông tin rút tiền',
    '成功': 'thành công',
    '失败': 'thất bại',
    'touxiang1': 'Thay đổi hình đại diện',
    'xitongtouxiang': 'Hình đại diện hệ thống',
    'album': 'Chọn từ album',
    'Photograph': 'ảnh chụp',
    'moren': 'Ảnh mặc định hệ thống',
    'xiaochu': 'Hủy bỏ',
    'cun2': 'cứu',
    all:'tất cả',
}
export default vi;
const jp={
	jiantizhongwen:'日本語',
    xianlu:'ライン',
    dianwei:'現在の地点',
    wodezichan:'私の資産',
    touzikuaixun:'投資ニュース',
    jiaoyiyonghu:'取引ユーザー',
    jiaoyipinglei:'取引カテゴリー',
    danlunhuoli:'1ラウンドで利益を得る',
    jiaoyiriqi:'取引日',
    dengluhouchakan:'ログイン後に見る',
    shouye:'ホーム',
    jiaoyidating:'取引センター',
    yucun:'事前保存',
    zoushi:'傾向',
    wode:'私の',
    zuiditouzi:'最低投資額',
    zuidijinru:'最低エントリー',
    jichulicai:'基礎',
    zishenlicai:'ベテラン',
    dashilicai: '大師',
    zhuanjialicai:'専門家',
    基础理财:'基礎',
    资深理财:'ベテラン',
    大师理财: '大師',
    专家理财:'専門家',
    基础:'基礎',
    资深:'ベテラン',
    大师: '大師',
    专家:'専門家',
    huiyuanquanyi:'会員特典',
    zuoriyingli:'昨日の利益',
    zongzichan:'総資産',
    chucunjin:'デポジット',
    jinriyingli:'今日の利益',
    zhuanchu:'出金',
    cunru:'デポジット',
    zhanghuxinxni:'口座情報',
    lishizhangdan:'歴史的な請求書',
    zijinminxi:'ファンドの詳細',
    renwuzhongxin:'ミッションセンター',
    xiaoxigonggao:'ニュース発表',
    tuijianfenxiang:'共有を推奨する',
    huodongzhongxin:'アクティビティセンター',
    jibenziliao:'基本情報',
    dengluzhanghao:'ログインアカウント',
    nicheng:'ニックネーム',
    shezhi:'設定',
    genggaixianlu:'ルート変更',
    guanyuwomen:'私たちについて',
    xitongshezhi:'システム設定',
    yuyanshezhi:'言語設定',
    tuichudenglu:'サインアウト',
    bangdingyinhangka:'銀行カードをバインドする',
    bangdingxuniqianbao:'仮想ウォレットをバインドする',
    anquanma:'セキュリティコード',
    xiugailenglumima:'ログインパスワードを変更する',
    yinhangkaguanli:'銀行カードの管理',
    tianjiayinhangka:'銀行カードを追加する',
    xuniqianbaoguanli:'仮想ウォレット管理',
    tianjiaxuniqianbao:'仮想ウォレットを追加する',
    chiakren:'カード所有者',
    yinhangkakahao:'銀行カード番号',
    yinhangmingcheng:'銀行名',
    yhkTps:'カード所有者のキャッシュカードをバインドしてください',
    nixiqianbaodizhi:'仮想ウォレットアドレス',
    xuniqianbaoleixing:'仮想ウォレットの種類',
    xnqbTps:' 注意: USDT アドレス タイプを入力してください (例: trc20、erc20、omni) ',
    plchikaren:'カード所有者を入力してください',
    plkahao:'カード所有者のカード番号を入力してください',
    plyinhangmingcheng:'銀行名を入力してください',
    plqbdz:'仮想ウォレットのアドレスを入力してください',
    plqblx:'仮想ウォレットのタイプを入力してください',
    shezhianquanma:'セキュリティコードを設定する',
    yuananquanma:'オリジナルのセキュリティコード',
    xinanquanma:'新しいセキュリティコード',
    planquanma:'元のセキュリティコードを入力してください',
    plxinanquanma:'新しいセキュリティコードを入力してください',
    queren:'確認する',
	quxiao:'キャンセル',
    anquanmaTps:' セキュリティコードを設定してください。キャッシュカードのパスワードと同じであってはなりません。',
    anquanmaTpsTow:' お客様各位 このセキュリティコードは転出時のパスワードとなりますので、セキュリティ上の理由から、ログインパスワードと同じものにならないようにご注意ください。',
    xiugaidenglu:'ログインパスワードを変更する',
    jiumima:'以前のパスワード',
    xinmima:'新しいパスワード',
    querenmima:'パスワードを認証する',
    pljiumima:'古いパスワードを入力してください',
    plxinmima:'新しいパスワードを入力してください',
    plquerenmima:'パスワードをもう一度入力してください',
    wancheng:'仕上げる',
    kaishitouzi:'投資を始める',
    kaiTps:'現在の回線が利用できない場合は、別の回線に切り替えてみてください',
    zuiyou:'最適な',
    dangaqianxianlu:'現在の行',
    dangqianbanben:'現行版',
    banquansuoyou:'無断転載を禁じます',
    yinglizhendong:'収益ショック',
    gensuixitong:'システムに従ってください',
    zhuanchujine:'払込金額',
    plzhuanchu:'送金額を入力してください',
    zhuanchuTps:'暖かいリマインダー: ご質問がある場合は、プラットフォームのカスタマー サービスにお問い合わせください。',
    xuanzeyh:'引き出し用の銀行カードを選択してください',
    xuanzehb:'出金用の仮想ウォレットを選択してください',
    tijiaoshenqing:'申請書を提出する',
    shuruanquanma:'セキュリティコードを入力してください',
    xianshangcunru:'オンラインで入金',
    saomacunru:'USDT',
    wangyincunru:"銀行預金",
    shijian:'時間',
    upshijian:'開始時間',
    downshijian:'終了時間',
    wuTps:'関連するデータはまだありません ~',
    jiazaicx:'リロード',
    di:'',
    lun:'期',
    weiyingli:'儲からない',
    yishouli:'承認されました',
    zhuangtai:'州',
    chongzhi:'リセット',
    riqi:'日付',
    CapActive:{
        chongzhi:'入金',
        tixian:'撤回する',
        goumai:'買う',
        yingli:'利益',
        zengsong:'譲る',
        weitongguo:'審査中',
        yitongguo:'通過する',
        yijujue:'拒否する',
    },
    zhanneixiaoxi:"駅ニュース",
    pingtaigonggao:'プラットフォームの発表',
    fuzhilanjie:"リンクをコピーする",
    denglu:'ログイン',
    zhuce:'登録する',
    jizhumima:'パスワードを覚える',
    wangjimima:'パスワードを忘れる',
    youkedenglu:'訪問者ログイン',
    zhucezhanghao:"アカウント登録",
    plusername:'ユーザー名を入力してください',
    plpassword:'パスワードを入力してください',
    wanchengzhuce:'登録を完了する',
    yiyouzhanghao:'すでにアカウントをお持ちですか',
    qingdenglu:'サインインしてください',
    remenhuati:'ホットな話題',
    news1:'オリジナル',
    news2:'推薦する',
    news3:'富',
    renliulan:'人々が閲覧する',
    hezhi:'合計値',
    lunshu:'期数',
    做多:'溢値',
    做空:'注値',
    多单:'溢奇',
    多双:'溢偶',
    空单:'注奇',
    空双:'注偶',
    平单:'奇値',
    平双:'偶値',
	极阴:'極陰',
	极阳:'極陽',
    qi:'期',
    juli:'距離',
    lunjiezhi:'ラウンド終了',
    yifengpan:'閉まっている',
    yiguanbi:'閉まっている',
    yonghu:'ユーザー',
    danlunjiaoyi:'取引量',
    caozuo:'操作する',
    pljoin:'チャット ルームに正常に参加しました',
    pltingshou:'製品は製造中止となりました',
    gengou:"フォローアップ購入",
	quedinggengou:"購入をフォローしてもよろしいですか?",
	wanjia:"プレーヤー",
	leibie:"カテゴリー",
	jine:"額",
    gouru:'購入',
    zanweigouru:'まだ購入していません',
    qigou:'最低購入',
    gourushuliang:'購入数量',
    changgui:'従来の',
    shuzi:'番号',
    yinglihezhi:'利益と価値',
    shouyijieshao:'収入紹介',
    lijicunru:'今すぐ入金',
    kaihaojieguo:'ナンバーオープニング結果',
    kaihaoqushi:'オープニングトレンド',
    chanpingshuoming:'製品説明',
    qingchuhuancun:'キャッシュの消去',
    youke:'観光客',
	gongxi:'おめでとう ',
	zai:' 存在する ',
	yingli:' 利益 ',
	xiadanchenggong:'正常に注文されました',
	jiazaizhong:'読み込み中...',
	guanbitg:'閉店、購入停止',
	xiangoudl:'現在の購入ラウンドのみをフォローできます',
	liaotiantip:'システムにより禁止されています。購入することを選択してください',
	tishi:'ヒント',
	zhidao:'知っていた',
	zhuanchusb:'転送に失敗しました',
	zhuanchusbs:'残高不足のため送金できませんでした',
	buyxq:'購入詳細',
	orderNo:'請求書番号',
	plan_name:'お部屋のご案内',
	buyxx:'購入情報',
	haoxx:'番号選択の詳細',
	buysj:'購入時間',
	yilou:'省略',
	fzjqb:'ペーストボードにコピーされました',
	chanpin1:'販売開始時間：3分半に1周、当日午前10時開店、翌日午前2時閉店。',
	chanpin2:'各ラウンドには0から9までの3つの数字があり、その3つの数字が最終結果に追加され、ロング、ショート、シングル、バイポーラ、アノード、カソードとその結果としての数字に投資することができます。',
    service: 'オンラインサービス',
	Tips:{
        wjmm:'パスワードを忘れた場合は、カスタマー サービスに連絡して変更してください。!',
        dlcg:'ログイン成功',
        tjcg:'正常に送信されました',
        zanwu:'データなし',
        buyizhi:'2 つのパスワードが矛盾しています',
        zcbz:'資産が不足していますので入金してください',
        pltxje:'出金額を入力してください',
        zdtx:'最低出金額 100',
		youke:'ご訪問のお客様へ：事前に正式会員登録をお願いいたします。',
		szcg:'セットアップに成功',
    },
    plemail: 'メールアドレスを入力してください',
    plcode: '確認コードを入力してください',
    sendcode: '確認コードを送信する',
    username: 'ユーザー名',
    email: '郵便',
    changepic:'アバターの変更',
    rule1:'ルールは次のとおりです',
    rule2:'オーバーフロー: 3 つの位置の値の合計が 14,15,16,17,18,19,20,21,22,23,24,25,26,27 以上です。',
    rule3:'注: 3 つの位置の合計は 00,01,02,03,04,05,06,07,08,09,10,11,12,13 以下です。',
    rule4:'Odd: 3 つの位置の値の合計が奇数になります。',
    rule5:'Even: 3 つの位置の値の合計が偶数になります。',
    rule6:'Yiqi (3 つの値の合計): 15、17、19、21、23、25、27。',
    rule7:'奇数 (3 つの値の合計) に注意してください: 01、03、05、07、09、11、13。',
    rule8:'オーバーフロー (3 つの値の合計): 14、16、18、20、22、24、26。',
    rule9:'注 (3 つの値の合計): 00、02、04、06、08、10、12',
    rule10:'その他の指示',
    rule11:'1. 上記のオッズには元本が含まれます。',
    rule12:'2. すべての形式の金額は入力後にのみ有効になります。そうでない場合、システムはそれを認識しません。',
    rule13:'3. プラットフォームの障害、ネットワークの問題、または賞品の紛失が発生した場合、全員が行ったすべての購入は無効となり、システムが復旧した後、元本は全額返金されます。',
    yigoumai:'買った',
    forgetpassword:'パスワードの取得',
    fanhuidenglu:'ログインページに戻る',
    fenhanghaoma:'支店番号',
    plsub:'支店番号を入力してください',
    plsubname:'支店名を入力してください',
    fendianmingcheng:'支店名',
    '最低購入100':'最低購入数 100',
    betall:'全て',
    yiqiehuan:'に切り替えました',
    '有未處理的請求 請聯係客服':'未処理のリクエストがある場合は、カスタマー サービスにお問い合わせください。',
    '體現信息錯誤': '出金情報エラー',
    '成功': '成功',
    '失败': '失敗',
    'touxiang1': 'アバターの変更',
    'xitongtouxiang': 'システムアバター',
    'album': 'アルバムから選ぶ',
    'Photograph': '写真',
    'moren': 'システムのデフォルト画像',
    'xiaochu': 'キャンセル',
    'cun2': '保存',
    all:'全て',
}
export default jp;
<template>
    <div class="language">
        <div class="settingwarp">
            <header>
                <div class="g-header" style="background: rgb(14, 21, 48)">
                    <div class="left" @click="goBack">
                        <van-icon name="arrow-left" color="#fff" size='20px' />
                    </div>
                    <div class="middle fs-18">{{ $t('xitongtouxiang') }}</div>
                    <div class="right">
                        <div class="fs-16" @click="confirmSelection">{{ $t('queren') }}</div>
                    </div>
                </div>
                <div class="blockHeight"></div>
            </header>
            <div class="g-content">
                <ul class="avatar-list clear">
                    <li v-for="(avatar, index) in avatarList"
                        :key="index"
                        class="avatar-item pos-r"
                        @click="selectAvatar(index)">
                        <!-- 修改图片路径为服务器路径/uploads/img + 图片名 -->
                        <img :src="`/uploads/img/${avatar.filename}`" class="avatar" alt="" />
                        <img v-if="selectedAvatarIndex === index"
                             src="@/assets/image/xuanze.png"
                             class="icon-radio"
                             alt="" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    // 导入axios
    import axios from 'axios';
    import userStore from "@/utils/userStore";  // 引入封装的存储工具
    export default {
        name: "Language",
        data() {
            return {
                avatarList: [
                    { filename: "auth.png" },
                    { filename: "auth1.png" },
                    { filename: "auth2.png" },
                    { filename: "auth3.png" },
                    { filename: "auth4.png" },
                    { filename: "auth5.png" },
                    { filename: "auth6.png" },
                    { filename: "auth7.png" },
                ],
                selectedAvatarIndex: null,
            };
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            confirmSelection() {
                const authToken = userStore.getToken();
                if (!authToken) {
                    this.$toast.fail(this.$t('qingdenglu'));
                    return {
                        code: 0,
                    };
                }
                if (this.selectedAvatarIndex !== null) {
                    const selectedAvatar = `/uploads/img/${this.avatarList[this.selectedAvatarIndex].filename}`;
                    const updateUrl = '/api/user/profile';
                    const formData = new FormData();
                    formData.append('avatar', selectedAvatar);
                    return axios.post(updateUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Token': authToken
                        }
                    })
                        .then(() => {
                            console.log('Avatar updated successfully');
                            this.$toast.success(this.$t('成功'));
                            return {
                                code: 1,
                            };
                        })
                        .catch(error => {
                            console.error('Error updating avatar:', error);
                            return {
                                code: 0,
                            };
                        });
                } else {
                    console.log('Please select an avatar first.');
                    return {
                        code: 0,
                    };
                }
            },
            selectAvatar(index) {
                this.selectedAvatarIndex = index;
            },
        },
    };
</script>


<style lang="less" scoped>
    .g-content {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        overflow: scroll;
        text-align: center;
        color: #a0a0a0;
    }

    .avatar-list {
    }

    .avatar-item {
        width: 25%;
        float: left;
        text-align: center;
        margin-top: 0.5rem;
    }

    .pos-r {
        position: relative;
    }

    .avatar {
        display: inline-block;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .icon-radio {
        position: absolute;
        width: 0.6rem;
        bottom: 0;
        right: 0.08rem;
    }
</style>
import request from "@/utils/request";
export default {
  // 用户注册
  register(username, password,email,code, group_id, inviter_id) {
    return request.post("/api/user/register", {
      username: username,
      password: password,
      email: email,
      code: code,
      group_id: group_id,
      inviter_id: inviter_id || "",
    });
  },
  // 用户登录
  login(account, password) {
    return request.post("/api/user/login", {
      account: account,
      password: password,
    });
  },
  // 发送验证码
  send(email,events) {
    return request.post("/api/ems/send", {
      email: email,
      event: events
    });
  },
  resetpwd(password,email,code) {
    return request.post("/api/user/resetpwd", {
      newpassword: password,
      email: email,
      captcha: code,
    });
  },
};
